<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-progress-linear
          color="primary"
          :indeterminate="loading"
          :active="loading"
          absolute
          top
        ></v-progress-linear>
        <v-card-title>
          <v-btn
            class="ma-2"
            color="secondary"
            @click="$router.back()"
            outlined
            dark
          >
            <v-icon>mdi-arrow-left</v-icon>
            Back
          </v-btn>
        </v-card-title>
        <v-card-title class="pl-6">Progress Report</v-card-title>
        <v-card-text v-if="!loading && student" class="pl-6 body-1"
          >{{ student.name }} ({{ student.email }})</v-card-text
        >
        <v-card-subtitle v-if="!loading && !student" class="pl-6 py-3"
          >Couldn't find student to display progress report.</v-card-subtitle
        >

        <div v-if="courses.length" class="pa-5">
          <v-tabs v-model="tabs" centered grow>
            <v-tab>Courses History</v-tab>
            <v-tab>Courses Answer Inputs</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabs">
            <v-tab-item>
              <v-card class="py-5 px-1">
                <v-expansion-panels v-if="!loading && courses.length" multiple>
                  <v-expansion-panel
                    v-for="course in courses"
                    :key="course.course_lookup_id"
                  >
                    <v-expansion-panel-header
                      class="font-weight-medium"
                      expand-icon="mdi-menu-down"
                    >
                      {{ course.name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-data-table
                        class="data-table"
                        :headers="getHistoryHeaders(course)"
                        :items="course.history"
                        :items-per-page="10"
                      >
                        <template
                          v-if="!course.is_scorm"
                          v-slot:item.action="{ item }"
                        >
                          <div v-html="item.action"></div>
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card class="py-5 px-1">
                <v-expansion-panels v-if="!loading && courses.length" multiple>
                  <v-expansion-panel
                    v-for="course in courses"
                    :key="course.course_lookup_id"
                  >
                    <v-expansion-panel-header
                      class="font-weight-medium"
                      expand-icon="mdi-menu-down"
                    >
                      {{ course.name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-data-table
                        class="data-table"
                        :headers="getAnswersHeaders(course)"
                        :items="course.answers"
                        :items-per-page="10"
                      >
                        <template v-slot:item.action="{ item }">
                          <div v-html="item.action"></div>
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </div>
        <v-card-text class="text-center" v-if="!loading && !courses.length"
          >No data available</v-card-text
        >
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
import { getById, getStudentProgressReport } from "@/services/studentService";
import { get, isEmpty } from "lodash";

export default {
  metaInfo: {
    title: "Progress Report",
  },
  data() {
    return {
      id: get(this.$route, "params.id"),
      loading: false,
      tabs: null,
      student: null,
      courses: [],
      options: {},
      courseHistoryHeaders: [
        { text: "Created", value: "created_at" },
        { text: "Action", value: "action", sortable: false },
      ],
      courseAnswersHeaders: [
        { text: "Created", value: "created_at" },
        { text: "Action", value: "action", sortable: false },
        { text: "Result", value: "result", sortable: false },
      ],
      scormHistoryHeaders: [
        { text: "Date Time", value: "latest_date" },
        { text: "Session Time", value: "session_time", sortable: false },
        { text: "Progression", value: "progression", sortable: false },
      ],
      scormAnswersHeaders: [
        { text: "Time", value: "time" },
        {
          text: "Action",
          value: "action",
          sortable: false,
        },
        { text: "Result", value: "result", sortable: false },
      ],
    };
  },
  async mounted() {
    this.loading = true;

    await this.getStudent();
    await this.getReports();

    this.loading = false;
  },
  methods: {
    async getStudent() {
      try {
        const { data } = await getById(this.id);

        if (!isEmpty(data)) {
          this.student = data;
        }
      } catch (error) {
        console.error("ERROR", error);
      }
    },
    async getReports() {
      try {
        const { data } = await getStudentProgressReport(this.id);

        if (!isEmpty(data)) {
          this.courses = data;
        }
      } catch (error) {
        console.error("ERROR", error);
      }
    },
    getHistoryHeaders(course) {
      return get(course, "is_scorm")
        ? this.scormHistoryHeaders
        : this.courseHistoryHeaders;
    },
    getAnswersHeaders(course) {
      return get(course, "is_scorm")
        ? this.scormAnswersHeaders
        : this.courseAnswersHeaders;
    },
  },
};
</script>
<style lang="scss" scoped></style>
